import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './main/main-index/login/login.component';
import { loginGuard } from './guards/login.guard';
import { ResetPasswordComponent } from './main/main-index/reset-password/reset-password.component';
import { HomeComponent } from './main/main-home/pages/home/home.component';
import { authGuard } from './guards/auth.guard';
import { homeGuard } from './guards/home.guard';
import { ErrorComponent } from './main/main-home/pages/error/error.component';
import { CalendarComponent } from './main/main-home/pages/agenda/calendar/calendar.component';
import { RoomComponent } from './main/main-home/pages/agenda/room/room.component';
import { routeGuard } from './guards/route.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    canActivate: [loginGuard]
  },
  {
    path: '',
    component: HomeComponent,
    // se loggato vai alla home, se non loggato vai a login
    canActivate: [authGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [homeGuard]
    // redirectTo: ''
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  // agenda in futuro se c'è bisogno si crea un modulo e una rotta dentro la cartella agenda
  {
    path: 'agenda/calendar',
    component: CalendarComponent
  },
  {
    path: 'agenda/room',
    component: RoomComponent,
    canActivate: [routeGuard]
  },
  // {
  //   path: 'anagrafica',
  //   loadChildren: () => import('./main/main-home/pages/anagrafica/anagrafica.module').then(m => m.AnagraficaModule)
  // },
  {
    path: 'customer',
    loadChildren: () => import('./main/main-home/pages/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'training',
    loadChildren: () => import('./main/main-home/pages/training/training.module').then(m => m.TrainigsModule)
  },
  {
    path: 'socivolontari',
    loadChildren: () => import('./main/main-home/pages/soci-volontari/soci-volontari.module').then(m => m.SociVolontariModule)
  },
  {
    path: 'lavorazioni/commesse',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/commesse/commesse.module').then(m => m.CommesseModule)
  },

  {
    path: 'lavorazioni/rdi',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/rdi/rdi.module').then(m => m.RdiModule)
  },
  {
    path: 'lavorazioni/report',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'lavorazioni/inverter',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/inverter/inverter.module').then(m => m.InverterModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./main/main-home/pages/settings/setting.module').then(m => m.SettingsModule)
  },
  {
    path: 'systems',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/systems/systems.module').then(m => m.SystemsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
